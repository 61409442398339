import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/system-crm-w-zarzadzaniu-vlog.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    zdjecie1: file(
      relativePath: { eq: "windykacja-nieoplacone-faktury-crm.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Specrm = ({ data }) => {
  return (
    <Artykul
      title="System CRM a miękka windykacja"
      keywords={["miękka windykacja w crm"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="System CRM a miękka windykacja"
      metaTitle="System CRM a miękka windykacja"
      metaDescription="Jak wygląda miękka windykacja w CRM? Jakie są jej zalety? Jak wykorzystywać funkcje CRM do usprawnienia procesów windykacyjnych? 
      Odpowiadamy w naszym nowym materiale."
    >
      <br />
      <p>
        Egzekwowanie terminowego opłacania rachunków przez kontrahentów nie jest
        łatwym zadaniem. Aby nieco je uprościć, przydają się cierpliwość,
        empatia oraz dobre narzędzie do prowadzenia procesów windykacyjnych. W
        poniższych materiałach krótko opisujemy, jak wygląda miękka windykacja w
        CRM i jak system ją usprawnia oraz automatyzuje.
      </p>
      <br />
      <ResponsiveEmbed
        src="https://www.youtube.com/embed/w5HmaTIlNnU"
        allowFullScreen
        ratio="16:9"
      />
      <br />
      <h2>Miękka windykacja - co to takiego?</h2>
      <p>
        Miękka windykacja jest polubowną wersją wezwania do zapłaty, której
        głównym celem jest łagodne wyjaśnienie sytuacji opóźnienia płatności.
        Należy pamiętać, że może być kilka różnych przyczyn przeterminowania się
        faktury. Klient mógł gdzieś ją zapodziać, zapomnieć o niej lub czekać aż
        inna firma zapłaci jemu, aby z kolei on mógł uregulować należność z
        nami. Tutaj właśnie przydaje się empatia oraz wyrozumiałość. Ważne jest,
        aby na drodze miękkiej windykacji móc określić akceptowalne dla obu
        stron warunki spłaty. Przed zarejestrowaniem klienta w rejestrze
        dłużników warto zacząć od prostego przypomnienia e-mail, kontaktu
        telefonicznego lub wysłania żądania płatności.
      </p>

      <p>
        Dlaczego bieżące przypominanie klientom o zaległych płatnościach jest
        warte zachodu?
      </p>
      <p>
        <ul>
          <li>
            to świetna okazja do tego, aby poprzez okazanie klientowi
            zrozumienia oraz zainteresowania jego biznesem, zacieśnić z nim
            relacje
          </li>
          <li>
            jeżeli powodem opóźnienia jest brak satysfakcji klienta, to bardzo
            prawdopodobnym jest, że dowiemy się o tym po przesłaniu
            powiadomienia o zaległej fakturze; to również jest okazja do poprawy
            relacji z klientem - kontakt w takiej chwili może uratować nie tylko
            płatność, ale również dalszą współpracę
          </li>
          <li>
            pomaga to również kontrolować finanse firmy, dostarczając informacje
            o ilości jeszcze nie sfinalizowanych faktur
          </li>
          <li>
            ponadto powiadomienia dostarczane w duchu miękkiej windykacji są
            mało inwazyjne i nienachalne
          </li>
        </ul>
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz sprawdzić jak wygląda miękka windykacja w CRM?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i poznać szczegóły miękkiej windykacji w CRM
            </Link>
          </div>
          </div>
    </section>
      <br />
      <h2>Miękka windykacja w systemie CRM</h2>
      <p>
        Tyle, jeżeli chodzi o teorię, teraz kilka słów na temat tego, jak
        opisany mechanizm przenieść w praktykę. System CRM oferuje tutaj kilka
        istotnych możliwości - automatyczną wysyłkę wiadomości e-mail,
        monitorowanie płatności za pomocą raportów oraz planowanie kontaktu w
        przypadku rażąco przeterminowanych faktur.
      </p>
      <br />
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie1]}
        alts={["Raport systemu CRM przedstawiający nieopłacone faktury"]}
      />
      <p>
        Automatyczny e-mail to bardzo dobry wstęp do miękkiej windykacji. W
        systemie CRM można utworzyć szablon takiej wiadomości i ustawić jej
        wysyłkę do wskazanych kontrahentów, którzy nie opłacili jeszcze faktur.
        Dzięki automatycznej wysyłce powiadomień z przypomnieniem o płatności,
        oszczędzamy dużo czasu na prostych zadaniach, które zwykle musimy
        wykonywać ręcznie.
      </p>

      <p>
        Projektując szablony takich wiadomości, należy mieć na uwadze kilka
        rzeczy:
      </p>
      <p>
        <ul>
          <li>
            powinny sprawiać wrażenie, że wysłał je człowiek, a nie automat
          </li>
          <li>
            powinny jasno wskazywać konkretną fakturę oraz umożliwiać jej
            pobranie
          </li>
          <li>
            powinny zawierać również informację o tym, kiedy upłynął termin
            płatności
          </li>
          <li>
            powinny też wskazywać osobę do kontaktu w przypadku pytań lub
            problemów
          </li>
        </ul>
      </p>
      <br />
      <h2>Na zakończenie</h2>
      <p>
        Stosujemy techniki miękkiej windykacji, aby zapewnić pozytywne relacje z
        klientami. Należy podkreślić, że najbardziej pożądanym wynikiem
        windykacji jest nie tylko nakłonienie klienta do spłaty, ale także
        jednoczesne utrzymanie z nim dobrych relacji biznesowych. Tylko w ten
        sposób możliwa jest dalsza współpraca.
      </p>

      <p>
        Główną zaletą korzystania z CRM w procesach windykacyjnych jest to, że
        możemy przekształcić proste relacje z klientami w długoterminowe
        partnerstwa. Miękka windykacja w CRM oznacza również poprawę wyników,
        jeżeli chodzi o ściągnięte należności. Tak więc jest nie tylko wydajna,
        ale również nie wyklucza możliwości dalszej współpracy z klientem.
      </p>

      <br />
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz sprawdzić jak wygląda miękka windykacja w CRM?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i poznać szczegóły miękkiej windykacji w CRM
            </Link>
          </div>
          </div>
    </section>
      <p>
        Jeżeli szukasz więcej informacji na temat efektywnej pracy na systemach
        CRM to zapraszamy, między innymi, tutaj:
      </p>
      <p>
        <ul>
          <li>
            <Link to="/wdrazanie-kultury-pracy-CRM">
              Wdrażanie kultury pracy z CRM w organizacji
            </Link>
          </li>
          <li>
            <Link to="/zarzadzanie-zespolem-marketingu-crm">
              Zarządzanie pracą zespołu marketingu w systemie CRM
            </Link>
          </li>
          <li>
            <Link to="/lejek-sprzedazy-crm">
              Lejek sprzedaży - wsparcie dla działu handlowego
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default Specrm;
